
<template>
    <div class="handwritten-name-wrap">
      <div class="opdia">
        <el-dialog custom-class='dia' :visible.sync="panelVisible" title="手写签名" width="100%" :destroy-on-close="true" class="signNameModel" top="0">
          <template slot="title">
            <div>
              <p>手写签名</p>
              <div class="gtnGroup">
                <el-button type="primary" size="mini" @click="save">提交</el-button>
              </div>
            </div>
          </template>
          <template>
            <div class="signWrap" @touchmove.prevent>
              <div class="signBox">
                <p> 此处手写输入（与现场一致或与原件一致）<el-button type="danger" size="mini" @click="clear">清空信息</el-button></p>
                <el-row class="mainBox">
                  <el-col :span="8">
                    <VueSignaturePad width="100%" height="100%" ref="sign1" :options="options" class="placeholderText" />
                  </el-col>
                  <el-col :span="8">
                    <VueSignaturePad width="100%" height="100%" ref="sign2" :options="options" class="placeholderText1" />
                  </el-col>
                  <el-col :span="8">
                    <VueSignaturePad width="100%" height="100%" ref="sign3" :options="options" class="placeholderText2" />
                  </el-col>
                  <el-col :span="8">
                    <VueSignaturePad width="100%" height="100%" ref="sign4" :options="options" class="placeholderText3" />
                  </el-col>
                  <el-col :span="8">
                    <VueSignaturePad width="100%" height="100%" ref="sign5" :options="options" class="placeholderText4" />
                  </el-col>
                  <el-col :span="8">
                  </el-col>
                </el-row>
              </div>
              <div class="signBox lastBox">
                <p> 此处签名<el-button type="danger" size="mini" @click="clearSign">清空签名</el-button></p>
                <VueSignaturePad width="100%" height="95%" ref="signature" :options="options" class="placeholderText7" />
              </div>
            </div>
          </template>

        </el-dialog>
      </div>
    </div>
</template>

<script>
// import { sumbitfile } from "@/api";
export default {

  data () {
    return {
      isCrop: false,
      lineWidth: 6,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      id: null,
      flag: false,
      panelVisible: false,
      submitVisible: false,
      panelTitle: "",
      options: {
        penColor: "#000",
        minWidth: 1,    //控制画笔最小宽度
        maxWidth: 1,    //控制画笔最大宽度
      },
      isActive1: true,
      isActive2: false,
      isActive3: false,
      imgSrc: "",
      imgSrc1: "",
      list: [1, 2, 3, 4, 5]
      // loading: false
    };
  },
  created () {
    // this.init()
  },
  mounted () {

  },
  methods: {
    handleReset () {
      this.$refs.esign.reset();
      this.resultImg = "";
    },
    //生成签名图片..
     handleGenerate () {
      setTimeout(async() => {
        if (this.imgSrc) {
        console.log(this.imgSrc);
        let randnum = Math.random() * 10000000000000;
        randnum = Math.floor(randnum);
        let fileName = "querenyijian/" + randnum + ".png";
        let fileName1 = "querenren/" + randnum + ".png";
        let file = await this.dataURLtoFile(this.imgSrc, fileName);
        let file1 = await this.dataURLtoFile(this.imgSrc1, fileName1);
        // console.log(file);
        let formdata = new FormData()
        formdata.append('commentPic', file, fileName)
        formdata.append('confirmorPic', file1, fileName1)
        this.$emit('imgurl',this.imgSrc,this.imgSrc1,formdata)
        this.panelVisible=false
        // console.log('params :>> ', params);
        // sumbitfile(this.id, formdata).then(res => {
        //   if (res && res.code === 200) {
        //     // console.log(111);
        //     this.$message.success("签名上传成功");
        //     this.submitVisible = false
        //     this.panelVisible = false;

        //     this.$router.go(0)
        //   }
        // })
      } else {
        this.$message.error('请签名之后提交！')
      }
      }, 1000);
  
     
    },
    //将base64转换为文件..
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    //手写签名按钮的点击
    handleClick () {
      this.panelVisible = true;
      // this.isActive1 = true;
      // this.isActive2 = false;
      // this.isActive3 = false;
      this.options = {
        penColor: "#000",
        minWidth: 2,
        maxWidth: 2,
      }
    },
    //撤销
    undo () {
      this.$refs.signaturePad.undoSignature();
    },
    //清除
    clear () {
      // this.$refs.signaturePad.clearSignature();
      this.list.map(el => {
        this.$refs[`sign${el}`].clearSignature()
      })
    },
    // 清楚签名
    clearSign () {
      this.$refs.signature.clearSignature();
    },
    //保存
    // async save () {
    //   // console.log(this.$refs.signaturePad.saveSignature());
    //   const data1 = this.$refs.signaturePad.saveSignature().data;
    //   // console.log('data :>> ', data);
    //   // this.imgSrc = await data;
    //   const data2 = this.$refs.signature.saveSignature().data
    //   this.drawAndShareImage(data1, data2)
    //   // this.handleGenerate()
    // },

    async save () {
      const a = this.list.every(el => this.$refs[`sign${el}`].saveSignature().data)
      if (!a) {
        this.$message.error('请完整填写')
        return
      }
      if (!this.$refs.signature.saveSignature().data) {
        this.$message.error('请签名')
        return
      }
      // this.mergeImgs(this.list, 80, 240).then((base64) => {
      //   // console.log('base64', base64)

      // })
      const base64 = await this.mergeImgs(this.list, 80, 240)
      this.imgSrc = base64
      this.ceratePic()
      // } else {
      //   this.$message.error('请签名')
      // }
      // }
    },
    // 生成签名照片
    ceratePic () {
      let canvas = document.createElement('canvas');
      canvas.width = 480;
      canvas.height = 240;
      let context = canvas.getContext('2d');
      context.rect(0, 0, canvas.width, canvas.height);
      let img = new Image();
      img.src = this.$refs.signature.saveSignature().data;
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        context.drawImage(img, 0, 0, 480, 200);
        let base64 = canvas.toDataURL('image/png');
        this.imgSrc1 = base64
        this.submitVisible = true
      }

    },
    mergeImgs (list, cwith, cheight) {
      return new Promise((resolve) => {
        const baseList = [];
        // 创建 canvas 节点并初始化
        const canvas = document.createElement('canvas');
        canvas.width = cwith * list.length;
        canvas.height = cheight;
        const context = canvas.getContext('2d');
        list.map((item, index) => {
          const img = new Image();
          img.src = this.$refs[`sign${item}`].saveSignature().data;
          // 跨域
          img.crossOrigin = 'Anonymous';
          img.onload = () => {
            context.drawImage(img, cwith * index, 50, cwith, 100);
            const base64 = canvas.toDataURL('image/png');
            baseList.push(base64);
            if (baseList[list.length - 1]) {
              // 返回新的图片
              resolve(baseList[list.length - 1]);
            }
          };
        });
        this.handleGenerate()

      });
    },
    //调节画笔粗细大小
    selSize (val) {
      this.options = {
        penColor: "#000",
        minWidth: val,
        maxWidth: val,
      };
      if (val == 1) {
        this.isActive1 = true;
        this.isActive2 = false;
        this.isActive3 = false;
      } else if (val == 2) {
        this.isActive1 = false;
        this.isActive2 = true;
        this.isActive3 = false;
      } else if (val == 3) {
        this.isActive1 = false;
        this.isActive2 = false;
        this.isActive3 = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">

// .placeholderText {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   background-image: url("../../assets/1.png");
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: 70%;
// }
// .placeholderText1 {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   background-image: url("../../assets/2.png");
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: 70%;
// }
// .placeholderText2 {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   background-image: url("../../assets/3.png");
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: 70%;
// }
// .placeholderText3 {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   background-image: url("../../assets/4.png");
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: 70%;
// }
// .placeholderText4 {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   background-image: url("../../assets/5.png");
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: 70%;
// }
// .placeholderText5 {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   background-image: url("../../assets/6.png");
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: 70%;
// }
// .placeholderText7 {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   background-image: url("../../assets/7.png");
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: 80%;
// }
// ::v-deep .dia {
//   height: 80% !important;
// }
// .qianming-container {
//   width: 100vw;
//   height: 40vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   box-sizing: border-box;
//   padding: 10px;
//   background: #efefef;
//   .contro-container {
//     width: 350px;
//     height: 50px;
//     display: flex;
//     // flex-direction: row;
//     align-items: center;
//     justify-content: space-around;
//     background-color: rgb(230, 230, 230);
//   }
//   // margin: 20px auto;
//   // position: relative;
// }
// .text {
//   font-size: 14px;
// }
// .item {
//   margin-bottom: 18px;
// }
// .card {
//   height: 200px;
//   width: 100%;
//   background: #fff;
// }
.handwritten-name-wrap {
  // width: 100%;
  // box-sizing: border-box;
  // padding: 10px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // margin-top: 10px;
  // border-top: 2px dotted rgb(231, 230, 230);
  // .btn {
  //   .el-button {
  //     // margin-bottom: 10px;
  //     padding: 12px 30px;
  //     font-size: 18px;
  //   }
  // }
  // .img-wrap {
  //   width: 100%;
  //   // height: 164px;
  //   height: 15vh;
  //   margin-left: 5px;
  //   border: 1px solid #ccc;
  //   img {
  //     width: 80%;
  //     height: 100%;
  //   }
  // }
  ::v-deep .el-dialog {
    margin: 0;
  }
  ::v-deep .el-dialog__wrapper {
    overflow: hidden !important;
  }
  ::v-deep .signNameModel {
    // height: 100vh;
    // .el-dialog {
    //   // height: 100%;
    //   // margin: 0 !important;
    // }

    .el-dialog__header {
      padding: 10px;
      border-bottom: 1px solid rgb(205, 205, 205);
      .el-dialog__headerbtn {
        top: 15px;
      }
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 50px;
        p {
          font-size: 16px;
          font-weight: bold;
          // margin-right: 100px;
        }
      }
    }
    .el-dialog__body {
      padding: 0;
      height: 90%;
      .otherSet {
        // width: 50%;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // .penTxt {
        //   // width: 70px;
        // }
        .selSize {
          width: 70px;
        }
        .el-select__caret {
          position: absolute;
          right: -3px;
        }
        .b1,
        .b2,
        .b3 {
          display: inline-block;
          background: #000;
          border-radius: 50%;
        }
        .circleWrap {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          cursor: pointer;
          margin-right: 20px;
        }
        .active {
          border: 1px dashed #0074d9;
        }
        .b1 {
          width: 4px;
          height: 4px;
        }
        .b2 {
          width: 6px;
          height: 6px;
        }
        .b3 {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
  .signWrap {
    // height: 100%;overflow.
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // border-top: 1px dotted rgb(231, 230, 230);
    .signBox {
      height: 50vh;
      margin-top: 5px;
      > p {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        .el-button {
          margin-left: 10px;
        }
      }
      // box-shadow: 1px 1px 5px 1px rgb(179, 179, 179);
      // border-top: 1px dotted rgb(179, 179, 179);
      // border-bottom: 1px dotted rgb(179, 179, 179);
      .mainBox {
        margin-top: 10px;
        height: 90%;
        .el-col {
          height: 50%;
          box-sizing: border-box;
          border: 1px dotted #999;
          border-left: none;
        }
        .el-col:nth-of-type(3n) {
          border-right: none;
        }
        .el-col:nth-of-type(1),
        .el-col:nth-of-type(2),
        .el-col:nth-of-type(3) {
          border-bottom: none;
        }
        // display: flex;
        // flex-wrap: w;
      }
    }
    .lastBox {
      height: 32vh;
      > p {
        border-bottom: 1px dotted rgb(179, 179, 179);
      }
    }
    .signName {
      flex: 1;
      border-top: 1px solid #ccc;
    }
    footer {
      // height: 40px;
      padding: 10px;
      border-top: 1px solid #ccc;
      .gtnGroup {
        // width: 50%;
        display: flex;
        justify-content: flex-end;
        // margin-left: 20px;
      }
    }
  }
}
// .signNameModel {
//   .vxe-modal--content {
//     padding: 0 !important;
//   }
// }
</style>
