<template>
  <div class='evidence'>
    <div class="top">
      <i class="el-icon-arrow-left" @click="$router.go(-1)"></i>
      现场取证
    </div>
    <div class="content">
      <van-form ref="evidenceForm" @submit="onSubmit">
        <van-field v-model="name" :disabled="disabledinputOnlySecond"  name="evidenceCollector" label="取证人：" placeholder="取证人" :rules="[{  required: true, message: '请填写取证人' }]" />
        <!--  -->
        <van-field :disabled="disabledinputOnlySecond" readonly :clickable="false" name="collaborator" :value="region" label="取证人2：" placeholder="请选择取证人2" @click="showPicker = !disabledinputOnlySecond" :rules="[{  required: true, message: '请选择取证人2' }]" />
        <van-popup :disabled="disabledinputOnlySecond" v-model="showPicker" position="bottom">
          <van-picker :disabled="disabledinputOnlySecond" show-toolbar :columns="columns" @confirm="onConfirm"  @cancel="showPicker = false" />
        </van-popup>
        <van-field :disabled="disabledinput" v-model="location" name="location" label="取证地点：" placeholder="取证地点" :rules="[{  required: true, message: '请填写取证地点' }]" />
        <!--  -->
        <van-field :disabled="disabledinput" readonly clickable name="collectionDate" :value="date1" label="取证时间：" placeholder="点击选择日期" @click="showCalendar = true" :rules="[{  required: true, message: '请选择取证时间' }]" />
        <van-calendar :disabled="disabledinput" v-model="showCalendar" @confirm="onConfirmTime" :min-date="minDate" :max-date="maxDate" color="#1989fa" />
        <!--  -->
        <van-field :disabled="disabledinput" v-model="desc" name="statement" label="问题说明：" placeholder="说明反映的问题（100字内）" :rules="[{  required: true, message: '请填写问题说明' }]" autosize type="textarea" maxlength="100" show-word-limit />
        <van-field :disabled="disabledinput" name="uploader" label="现场照片：" :rules="[{  required: true, message: '请上传现场照片' }]">
          <template #input>
            <van-uploader v-model="fileList" max-count="2" accept="image/*" :disabled="disabledinput" :before-read="beforeRead"  >
              <img class="uploadIcon" src="@/assets/addimage.png" alt="" >
            </van-uploader>
          </template>
        </van-field>
        <van-field v-model="phone" name="confirmWay" label="确认方式："  :rules='typeRules'>
          <template #input>
             <van-radio-group v-model="confirmWay" direction="horizontal" @change="confirmWayChange" :disabled="confirmdis">
               <van-radio name="1">现场签字</van-radio>
               <van-radio name="2">手机短信</van-radio>
             </van-radio-group>
           </template>
        </van-field>
        <!--短信 -->
        <van-field v-if="confirmWay==2" v-model="phone" name="respondentPhone" label="确认人手机号码：" placeholder="请输入手机号码" :rules="phoneRule" >
          <template #button>
           <van-button size="small" native-type="button" style="color: #fff;background-color: #1284ff" @click="sendMessage" :disabled="allDisable" v-show="status!==1">发送</van-button>
          </template>
        </van-field>
        <!-- 现场 -->
        <van-field v-model="write" v-if="confirmWay==1"  name="write" label="确认人:" placeholder="点击签字" readonly clickable @click="writeopen" :rules="writeRule">
         <template #input>
          <div v-show="write!==''" >
            <div class="imgbox" >
             <img :src=" writeRemark" alt="">
            </div>
            <div class="imgbox" >
             <img :src="write" alt="">
            </div>
          <div style="float: right;color: #1284ff;">重新签字 <van-icon name="arrow" style="color: #999999;"/></div>
          </div>
          <div class="writeTip" v-show="write==''" @click="writeopen" ><div>点击签字</div> <van-icon name="arrow" /></div>
         </template>
        </van-field>


        <div class="phoneBox" v-if="confirmWay==2">
          <div>确认人：</div>
          <div class="imgs">
            <div class="imgbox" >
             <img :src="writeRemark" alt=""  v-show="write">
            </div>
            <div class="imgbox" >
             <img :src="write" alt=""  v-show="write">
            </div>
          <div v-show="deleteSignFlag==false&&write&&status!==1" style="float: right;color: #EA1D1D;" @click="deleteSign">删除签字 <van-icon name="arrow" style="color: #999999;"/></div>
          <div v-show="deleteSignFlag==true&&status!==1" style="float: right;color: #1284ff;" @click="regainSign">恢复签字 <van-icon name="arrow" style="color: #999999;"/></div>
        </div>
        </div>
      </van-form>
    </div>
    <van-button @click="enter" :disabled="allDisable"  :loading="loading" class="submitBtn">{{ enterText }}</van-button>
    <!-- <writeBox ref="write" @imgurl="imgurl"/> -->
    <writeRemark ref="write"  @imgurl="imgurl"/>
  </div>
</template>

<script>
import { getUserList,uploadMsg,getEvidenceById,sendMsg ,sumbitfile,clearSign} from '@/api'
import { mapGetters } from 'vuex'
// import writeBox from './write'
import writeRemark from "./writeRemark";
import { Dialog } from 'vant';
import ImageCompressor from "image-compressor.js";
// import EXIF from 'exif-js'
export default {
  components:{writeRemark},
  beforeRouteLeave(to, from,next) {
    // console.log(to,from)
    if (this.status ==2 &&this.confirmWay&&this.write!=='') {
      Dialog.confirm({
      title: '标题',
      message: '签名未保存，是否确认离开本页',
    })
    .then(() => {
      next()
    })
    .catch(() => {
      next(false)
     });
    }else if (!this.status) {
      Dialog.confirm({
      title: '标题',
      message: '草稿未保存，是否确认离开本页',
    })
    .then(() => {
      next()
    })
    .catch(() => {
      next(false)
    });
    }else{
      next()
    }
  },
  data () {
    return {
      // 取证人
      name: '',
      // 协查人
      region: '',
      // 取证地点
      location: '',
      // 确认人手机号码
      phone: '',
      // 时间
      date1: this.$moment().format('YYYY-MM-DD'),
      // 问题说明
      desc: '',
      // 现场照片
      fileList: [],
      // 协查人员选择
      columns: [],
      // 签字方式
      confirmWay:'',
      // 现场签字
      write:'',
      // 意见
      writeRemark:'',
      showdia:false,
      showPicker: false,
      showCalendar: false,
      loading: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(),
      btnDisable: false,
      // savedraft:false,
      status:null,
      imgList:[],
      id:null,
      signFormDate:null,
      signed:null,
      deleteSignFlag:false,
      userList:[],
      collaborator_idL:null,
      mainPeople:this.$store.state.user.userInfo.id,
      secondPeople:this.$store.state.user.userInfo.id,
      infolist:{}
    };
  },
  computed:{
    ...mapGetters(['userId']),
    phoneRule(){
      if (this.confirmWay==2&&this.status&&this.status!==2) {
        return [{required: true,message:'请输入手机号'}]
      }else{
        return []
      }
    },
    writeRule(){
      if (this.confirmWay==1&&this.status&&this.status!==2) {
        return [{  required: true, message: '请签字' }]
      }else{
        return []
      }
    },
     disabledinputOnlySecond(){
      if (this.status&&this.$store.state.user.userInfo.id==this.secondPeople&&this.$store.state.user.userInfo.id!=this.mainPeople) {
        return true
      }else if (this.status&&this.$store.state.user.userInfo.id==this.secondPeople&&this.$store.state.user.userInfo.id!=this.mainPeople) {
        return true
      }else{
        return false
      }
     },
     disabledinput(){
      if (this.status!==2&&this.status) {
         return true
       }else{
        return false
       }
     },
     enterText(){
      if (!this.status&&this.write=='') {
        return '保存草稿'
      }else {
        return this.signFormDate===null&&this.status!==1?'确认提交':this.signFormDate1==null&&this.status!==1?'提交签字':'取证结束'
      }

     },
     allDisable(){
      return this.status===1?true:false
     },
     typeRules(){
      if (this.enterText=='保存草稿'||!this.status) {
        return []
      }else{
       return [{  required: true, message: '请选择确认方式' }]
      }
     },
     confirmdis(){
      if(this.status==1){
        return true
      }else{
        return false
      }
     }

  },
  watch:{
    status(newval){
    //  console.log(newval);
     if (this.confirmWay==2&&this.status==3&&!this.write) {
      setInterval(() => {
        this.getphoneSign()
      }, 60000);
     }else{
      clearInterval
      if (newval==1) {
        this.$router.replace({
          name:'Attest',
          params:{id:this.id},
          query:{status:this.status}
        })
      }
     }
    },

  },
  created () {
    if (this.$route.query.id) {
      this.init()
      this.getinfo(this.$route.query.id)
    }else{
      this.init()
    }
  },
  mounted(){

  },
  destroyed(){
    clearInterval
  },
  methods: {
    // 获取人员列表
    init () {
      if (localStorage.getItem('userName')) {
        this.name = localStorage.getItem('userName')
      }
      const params = {
        pageNum: 1,
        pageSize: 100
      }
      getUserList(params).then(res => {
        const { rows } = res
        const arr = rows.map(el => {
          return el.user.nickname
        })
        this.userList = rows.map(el => {
          return {name:el.user.nickname ,id:el.user.id}
        })
        this.columns = arr
      })
    },
    // 回显获取
   async getinfo(val){
      const res = await getEvidenceById(val)
      this.infolist = res.data
      this.name=res.data.evidenceCollector
      this.region=res.data.collaborator
      this.location=res.data.location
      this.desc=res.data.statement
      this.date1=this.$moment(res.data.collectionDate).format('YYYY-MM-DD')
      this.id=res.data.id
      this.status=res.data.status?res.data.status:2
      this.phone=res.data.respondentPhone
      if (res.data.confirmorPic!=null) {
      this.write=`http://record.ggws.com.cn/${res.data.confirmorPic}`
     }
     if (res.data.commentPic!=null) {
      this.writeRemark=`http://record.ggws.com.cn/${res.data.commentPic}`
     }
      this.signed=res.data.confirmorPic
      if (res.data.signType) {
        this.confirmWay =String(res.data.signType)
      }
      this.collaborator_id=res.data.collaboratorId
      this.imgList.push(res.data.confirmorPic,res.data.commentPic)
      JSON.parse(res.data.evidencePic).map(i=>{
        this.fileList.push({url:`http://record.ggws.com.cn/${i}`})
      })
      // console.log(this.fileList);
     this.mainPeople=res.data.evidenceCollectorId
     this.secondPeople=res.data.collaboratorId
    },
    onConfirmTime (date) {
      this.date1 = this.$moment(date).format('YYYY-MM-DD');
      this.showCalendar = false;
    },
    onConfirm (value) {
      this.region = value;
      this.collaborator_id=this.userList.filter(i=>{return i.name==value})[0].id
      this.showPicker = false;
    },
   async enter () {
    if (this.enterText=='保存草稿') {
      this.status=2
      this.$refs.evidenceForm.submit()
    }else{
     if (this.signFormDate!==null&&this.signed==null&&this.confirmWay==1) {
        this.signFormDate.append('signType',Number(this.confirmWay))
        const res = await sumbitfile(this.id, this.signFormDate)
        if (res.code==200) {
        this.status=4
        this.signFormDate=null
        this.$refs.evidenceForm.submit()
        this.signed=this.write
       }
      }else if(this.signFormDate!==null&&this.signed!==null&&this.confirmWay==1) {
      const res = await clearSign(this.id)
      console.log(res);
      this.signFormDate.append('signType',Number(this.confirmWay))
      await sumbitfile(this.id, this.signFormDate)
      this.signFormDate=null
      this.$refs.evidenceForm.submit()
      }else{
        const res = await getEvidenceById(this.id)
        if (res.data.confirmorPic) {
          this.status=1
          this.write=`http://record.ggws.com.cn/${res.data.confirmorPic}`
          this.writeRemark=`http://record.ggws.com.cn/${res.data.commentPic}`
          this.$refs.evidenceForm.submit()
          this.confirmWay =String(res.data.signType)
          this.imgList.push(`http://record.ggws.com.cn/${res.data.confirmorPic}`,`http://record.ggws.com.cn/${res.data.commentPic}`)
        
          // this.$router.go(-1)
      }else{
          this.$Toast.fail(`${this.confirmWay==1?'请签字后提交':'签字未完成,暂不可提交'}`)
        }
      }
    }
    
    },
    // 现场签字
    writeopen(){
      if (this.status===1) {
        return
      }else{
        this.$refs.write.handleClick()
        this.showdia=true
      }

    },
    // 现场签字返回
  async imgurl(A,B,C){
      this.write=B
      this.writeRemark=A
      this.imgList.push(A,B)
      // console.log(this.imgList);
      this.signFormDate=C
    },
    //选择并且保存草稿
    confirmWayChange(){
      if (!this.status||this.status==2) {
        this.status=2
        this.$refs.evidenceForm.submit()
      }else{
        return
      }
    },
    onSubmit (values) {
      // console.log(4);
      // console.log(this.status);
      // console.log('values', values)
      this.loading = true
      const formdata = new FormData()
      const { collaborator, collectionDate, evidenceCollector, location, statement, uploader } = values
      // console.log(values);
      const params = {
        collaborator, collectionDate, evidenceCollector, location, respondentPhone:this.phone, statement,status:this.status,id:this.id,
        collaboratorId:Number(this.collaborator_id),evidenceCollectorId:Number(this.$store.state.user.userInfo.id)
      }
      const file=[]
      uploader.map(async(el) => {
        // console.log(el.url);
        if (el.file) {
          formdata.append('files', el.file)
        }else{
         file.push((el.url.split('cn/'))[1])
        }
      })
     this.$set(params,'evidencePic',JSON.stringify(file))

      formdata.append('evidence', JSON.stringify(params))

      uploadMsg(formdata).then(res => {
        if (res.code === 200) {
          this.id=res.data.id
          this.status=res.data.status
          this.$Toast.success(`${this.status==2?'取证草稿已保存':this.status==4&&this.confirmWay==1?'签名已保存':this.status==1?'取证已完成':'签名短信已发送'}`)
          this.btnDisable = true
          this.loading = false
        } else {
          this.$Toast.fail('发送失败，请重新尝试')
          this.loading = false
        }
      })
    },
   async sendMessage(){
    if (this.write&&this.deleteSignFlag==false) {
      this.$Toast.success('请先删除签名')
      return
     }else{
       const res = await sendMsg([this.id,this.phone])
      if (res.code === 200) {
        this.$Toast.success('短信已发送')
        this.status=3
       await this.$refs.evidenceForm.submit()
      }
     }
    },
    deleteSign(){
      this.deleteSignFlag=true
      this.write=''
      this.writeRemark=''
      this.signFormDate=null
    },
    async getphoneSign(){
      const res = await getEvidenceById(this.id)
        this.write=`http://record.ggws.com.cn/${res.data.confirmorPic}`
      this.writeRemark=`http://record.ggws.com.cn/${res.data.commentPic}`
    },
    regainSign(){
      this.deleteSignFlag=false
      if (this.status==2) {
        this.write=`${this.imgList[this.imgList.length-2]}`
      this.writeRemark=`${this.imgList[this.imgList.length-1]}`
      }else{
        this.write=`http://record.ggws.com.cn/${this.imgList[0]}`
      this.writeRemark=`http://record.ggws.com.cn/${this.imgList[1]}`
      }
      
    },
    // 关于ios竖图变横图
    beforeRead(file){
      if (file.type.indexOf("image") == "-1") {
        return file;
      }
      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向 Compressor
        new ImageCompressor(file, {
          quality: 0.1,
          checkOrientation: this.checkOrientation,
          success(res) {
            res = new File([res], res.name, {
              type: res.type,
              lastModified: Date.now(),
            });
            resolve(res);
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
},

};
</script>

<style scoped lang="scss">
.evidence {
  height: 100vh;
  // box-sizing: border-box;
  // padding: 45px 0 60px;
  padding-top:45px;
  position: relative;
  .top {
    position: fixed;
    background: linear-gradient(92deg, #1284ff, #43abff);
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    padding: 14px 0;
    font-size: 17px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 1;
    ::v-deep .el-icon-arrow-left {
      position: absolute;
      top: 14px;
      left: 13px;
    }
  }
  .content {
    flex: 1;
    background: #ffffff;
    padding: 10px 24px 50px 16px;
    .uploadIcon{
          width: 100px;
        }
    .imgbox{
      border: 1px solid #CACACA;
      border-radius: 8px;
      padding: 10px 15px;
      height: 100px;
      // width: 100%;
      img{
        height: 100%;
        width: 100%;
      }
    }
    .retunwrite{
      color:  #1284FF;
      // float: right;
    }
    .writeTip{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #1284ff;
    }
    .phoneBox{
      width: 100%;
      display: flex;
      font-size:17px;
      white-space: normal !important;
    text-align: left;
    font-weight: 500;
    color: #666666;
    margin-bottom:30px;
    .imgs{
      flex:1
    }
    }
    .imgListBox{
      width:100px;
      height:100px;
      position: relative;
      margin-right:20px;
      border: 1px solid #CACACA;
      img{
        width: 100%;
      }
      span{
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    ::v-deep .van-cell::after {
      border: none;
    }
    ::v-deep .van-cell {
      display: flex;
      padding: 0;
      margin-bottom: 30px;
      border: none;
      .van-field__label {
        white-space: normal !important;
        text-align: left;
        font-size: 17px;
        font-weight: 500;
        color: #666666;
        width: initial;
        margin: 0;
        // padding-right: 12px;
        padding: 0;
      }
      .van-field__body {
        font-size: 17px;
        color: #333;
      }
      .el-form-item__content {
        flex: 1;
        .el-select {
          width: 100%;
        }
        
        .el-upload {
          width: 80px;
          height: 80px;
          line-height: 80px;
        }
        .el-upload-list {
          .el-upload-list__item {
            width: 80px;
            height: 80px;
          }
          .el-upload-list__item-actions:target {
            opacity: 1;
            span {
              display: inline-block;
            }
          }
        }
      }
      .el-input {
        border: none;
        width: 100%;
        .el-input__inner {
          border: none;
          color: #333;
          // padding: 0;
        }
      }
      // &:last-child{
      //   display: none;
      // }
    }
    ::v-deep .remark{
      display: block;
      .van-cell__value{
        padding: 5px 3px 0;
        border: 1px solid #CACACA;
        border-radius: 8px;
      }
    }
  }
  .van-button--disabled {
    opacity:1
  }
  .submitBtn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: linear-gradient(92deg, #1284ff, #43abff);
    line-height: 60px;
    padding: 0;
    border: none;
    color: #ffffff;
    font-size: 17px;
  }
}
</style>
<!-- 判断
1.已完成：已经提交，不可修改 readonly全部限制
2.未完成：
  2.1草稿（可编辑）：
    2.1.1现场草稿——未签字（write字段空）
    2.1.2短信草稿——未发送短信（短信flag=false）
  2.2待签字（只限短信 即flag=true，mesimg=null,可修改readonly）
  2.3待确认（write!==''|| flag=true，mesimg=String，可修改readonly）-->
  